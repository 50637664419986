import { Inject, Injectable, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import {
  GlobalSettings,
  DEFAULT_LANGUAGE_ID,
  Locale,
  Configuration,
  ENGLISH_LANGUAGE_ID,
  CZECH_LANGUAGE_ID,
  UKRAINIAN_LANGUAGE_ID,
  POLISH_LANGUAGE_ID,
  ROMANIAN_LANGUAGE_ID,
  FRENCH_LANGUAGE_ID,
  LOCALES,
  TURKISH_LANGUAGE_ID,
  SPANISH_LANGUAGE_ID,
  RUSSIAN_LANGUAGE_ID,
  ITALIAN_LANGUAGE_ID,

} from '../lib/lib';
import { LoggingService } from './logging/logging.service';
import { MachineRootService } from './machines/machine-root.service';
import { DispatcherService } from './dispatcher.service';
import { MachineSaleShopService } from './machines/machine-sale.service';
import { ConfigurationService } from './configuration/configuration.service';
import { AdditionalPropertiesConfigurationService } from './configuration/additional-properties-configuration.service';

import { deLocale, enGbLocale, csLocale, plLocale, roLocale, frLocale, ukLocale, trLocale, esLocale, ruLocale, itLocale } from 'ngx-bootstrap/locale';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import localeUa from '@angular/common/locales/uk';
import localeFr from '@angular/common/locales/fr';
import localeRo from '@angular/common/locales/ro';
import localeTr from '@angular/common/locales/tr';
import localeEs from '@angular/common/locales/es';
import localeRu from '@angular/common/locales/ru';
import localeIt from '@angular/common/locales/it';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

const COOKIE_KEY = 'locale';

export function getLanguageFactory(languageService: LanguageService): string {
  return languageService.getLanguage();
}

@Injectable()
export class LanguageService {
  private languageTimer: any = null;
  private iSelectedLocale: Locale;
  private iDefaultLanguageId = DEFAULT_LANGUAGE_ID;
  private languageTimeout = 120000;
  private log: LoggingService;
  private machineRootService: MachineRootService;
  private dispatcherService: DispatcherService;
  private machineSaleShopService: MachineSaleShopService;
  private configurationService: ConfigurationService;
  private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;
  private _cssClassName;

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private cookieService: CookieService,
    private bsLocaleService: BsLocaleService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.dispatcherService = this.injector.get(DispatcherService);
    this.translateService.setDefaultLang(DEFAULT_LANGUAGE_ID);
    const languageId = this.cookieService.get(COOKIE_KEY);
    const id = languageId ? languageId : DEFAULT_LANGUAGE_ID;
    this.setSelectedLanguageById(id);
    this.dispatcherService.eventServiceInitialized.subscribe(() => this.eventServiceInitialized());
  }

  private eventServiceInitialized(): void {
    this.log = this.injector.get(LoggingService);
    this.machineRootService = this.injector.get(MachineRootService);
    this.machineRootService.eventStateChanged.subscribe(() => this.languageTimerRefresh());
    this.machineSaleShopService = this.injector.get(MachineSaleShopService);
    this.machineSaleShopService.eventStateChanged.subscribe(() => this.languageTimerRefresh());
    this.configurationService = this.injector.get(ConfigurationService);
    this.additionalPropertiesConfigurationService = this.injector.get(AdditionalPropertiesConfigurationService);
    this.dispatcherService.onConfigurationChangedSubscribe((x) => this._ConfigurationChanged(x));
  }

  private _ConfigurationChanged(configuration: Configuration): void {
    this.languageTimeout = configuration.languageTimeout;
    const defaultLanguageId = this.defaultLanguageId;
    if (this.avalibleLocalesForCustomer && this.avalibleLocalesForCustomer.length !== 0
      && this.avalibleLocalesForCustomer.findIndex(x => x.id === defaultLanguageId) === -1) {
      // this.defaultLanguageId = this.avalibleLocalesForCustomer[0].id;
      this.selectedLocale = this.avalibleLocalesForCustomer[0];
    }
  }

  private languageTimerRefresh(): void {
    const isNotDefaultLocale = !this.isDefaultLocale(this.selectedLocale);
    if (isNotDefaultLocale && this.isMachineStateReadyToLanguageReset) {
      this.languageTimerStart();
    } else {
      this.languageTimerStop();
    }
  }

  private get isMachineStateReadyToLanguageReset(): boolean {
    let result = false;

    const rootMachineState = this.machineRootService == null ? null : this.machineRootService.state;
    const saleMachineState = this.machineSaleShopService == null ? null : this.machineSaleShopService.state;
    if (this.configurationService != null && this.configurationService.showArticlesOnMainPage) {
      result = ['sale', 'nonOperational'].includes(rootMachineState) && ['off', 'idle'].includes(saleMachineState);
    } else {
      result = ['idle', 'nonOperational'].includes(rootMachineState);
    }

    return result;
  }

  get locales(): Locale[] {
    return LOCALES;
  }

  get selectedLocale(): Locale {
    return this.iSelectedLocale;
  }

  set selectedLocale(value: Locale) {
    const languages = this.locales.filter(x => x === value);
    if (languages.length === 0) {
      this.log.error(`LanguageService. selectedLocale. locale not found: '${value}'`);
      return;
    }

    this.changeServerLanguage();

    if (this.iSelectedLocale === value) {
      return;
    }

    this.iSelectedLocale = value;
    this.cookieService.put(COOKIE_KEY, value.id);
    this.translateService.use(value.id);
    const scope = this;
    GlobalSettings.setCurrentLocale(this.iSelectedLocale.id);
    GlobalSettings.setTranslateFunc((x) => scope.translateService.instant(x));

    this.updateDefineLocale();
    this._updateBodyLangClassName();

    if (this.dispatcherService) {
      this.dispatcherService.languageChanged();
    }

    this.languageTimerRefresh();
  }

  private _updateBodyLangClassName(): void {
    const cssClassName = this.iSelectedLocale?.id ? 'lang-' + this.iSelectedLocale.id.slice(0, 2): '';
    if (this._cssClassName && this._cssClassName !== cssClassName) {
      this.document.body.classList.remove(this._cssClassName);
    }

    if (this._cssClassName !== cssClassName && cssClassName) {
      this.document.body.classList.add(cssClassName);
      this._cssClassName = cssClassName;
    }
  }

  changeServerLanguage(): void {
    if (!this.dispatcherService || !this.dispatcherService.vuHttp) {
      return;
    }

    const localeId = this.getLanguage();
    this.dispatcherService.vuHttp.changeServerLanguage(localeId);
  }

  updateDefineLocale(): void {
    const localeId = this.getLanguage();
    switch (localeId) {
      case ENGLISH_LANGUAGE_ID:
        registerLocaleData(localeEn, localeId);
        this.applyBootstrapLocale('en', enGbLocale);
        break;
      case CZECH_LANGUAGE_ID:
        registerLocaleData(localeCs, localeId);
        this.applyBootstrapLocale('cs', csLocale);
        break;
      case UKRAINIAN_LANGUAGE_ID:
        registerLocaleData(localeUa, localeId);
        this.applyBootstrapLocale('uk', ukLocale);
        break;
      case POLISH_LANGUAGE_ID:
        registerLocaleData(localePl, localeId);
        this.applyBootstrapLocale('pl', plLocale);
        break;
      case FRENCH_LANGUAGE_ID:
        registerLocaleData(localeFr, localeId);
        this.applyBootstrapLocale('fr', frLocale);
        break;
      case ROMANIAN_LANGUAGE_ID:
        registerLocaleData(localeRo, localeId);
        this.applyBootstrapLocale('ro', roLocale);
        break;
      case TURKISH_LANGUAGE_ID:
        registerLocaleData(localeTr, localeId);
        this.applyBootstrapLocale('tr', trLocale);
        break;
      case SPANISH_LANGUAGE_ID:
        registerLocaleData(localeEs, localeId);
        this.applyBootstrapLocale('es', esLocale);
        break;
      case RUSSIAN_LANGUAGE_ID:
        registerLocaleData(localeRu, localeId);
        this.applyBootstrapLocale('ru', ruLocale);
        break;
      case ITALIAN_LANGUAGE_ID:
        registerLocaleData(localeIt, localeId);
        this.applyBootstrapLocale('it', itLocale);
        break;
      default:
        registerLocaleData(localeDe, localeId);
        this.applyBootstrapLocale('de', deLocale);
        break;
    }
  }

  applyBootstrapLocale(localeName: string, localeData: any): void {
    defineLocale(localeName, localeData);
    this.bsLocaleService.use(localeName);
  }

  setSelectedLanguageById(value: string): void {
    const locale = this.getLocaleByLanguageId(value);
    if (locale == null) {
      return;
    }
    this.selectedLocale = locale;
  }

  getLocaleByLanguageId(value: string): Locale {
    const locales = this.locales.filter(x => x.id === value);
    if (locales.length > 0) {
      return locales[0];
    }

    this.log.error(`LanguageService. getLocaleByLanguageId. locale not found by: '${value}'`);
    return null;
  }

  set defaultLanguageId(value: string) {
    this.iDefaultLanguageId = value;
    if (this.log != null) {
      this.log.info(`LanguageService. set defaultLanguage: ${value}`);
    }
  }

  get defaultLanguageId(): string {
    return this.iDefaultLanguageId;
  }

  resetToDefaultLanguage(): void {
    this.setSelectedLanguageById(this.defaultLanguageId);
    if (this.log != null) {
      this.log.info(`LanguageService. resetToDefaultLanguage: ${this.defaultLanguageId}`);
    }
  }

  isDefaultLocale(value: Locale): boolean {
    const defaultLocale = this.getLocaleByLanguageId(this.defaultLanguageId);
    return defaultLocale != null && value === defaultLocale;
  }

  get odooLanguageName(): string {
    return Locale.convertToOdooLanguageId(this.getLanguage() || DEFAULT_LANGUAGE_ID);
  }

  getLanguage(): string {
    return this.iSelectedLocale.id;
  }

  getDisplayName(): string {
    return this.iSelectedLocale.displayName;
  }

  private languageTimerStart(): void {
    this.languageTimerStop();
    const scope = this;
    this.languageTimer = setTimeout(() => {
      scope.resetToDefaultLanguage();
    }, this.languageTimeout);
  }

  private languageTimerStop(): void {
    if (this.languageTimer != null) {
      clearTimeout(this.languageTimer);
      this.languageTimer = null;
    }
  }

  get defaultLocale(): Locale {
    return this.getLocaleByLanguageId(this.defaultLanguageId);
  }

  get avalibleLocalesForCustomer(): Locale[] {
    const locales = this.locales;

    const supportLanguages = this.additionalPropertiesConfigurationService.supportLanguages;
    if (!supportLanguages || supportLanguages.length === 0) {
      return [this.defaultLocale];
    }

    const filteredLocales = locales.filter(x => supportLanguages.map(val => val.replace('_', '-')).includes(x.id));
    if (!filteredLocales.some(val => val === this.defaultLocale)) {
      filteredLocales.push(this.defaultLocale);
    }

    if (filteredLocales) {
      return filteredLocales;
    }

    return [this.defaultLocale];
  }
  get supportLanguagesCount(): number {
    if (this.additionalPropertiesConfigurationService && this.additionalPropertiesConfigurationService.supportLanguages) {
      return this.avalibleLocalesForCustomer.length;
    }
    return 0;
  }

  get showLanguagesButton(): boolean {
    return this.supportLanguagesCount > 1;
  }

  flags = {
    'de-DE': '/static/images/flags/de-DE.png',
    'en-US': '/static/images/flags/en-US.png',
    'uk-UA': '/static/images/flags/uk-UA.png',
    'cs-CZ': '/static/images/flags/cs-CZ.png',
    'pl-PL': '/static/images/flags/pl-PL.png',
    'fr-FR': '/static/images/flags/fr-FR.png',
    'ro-RO': '/static/images/flags/ro-RO.png',
    'tr-TR': '/static/images/flags/tr-TR.png',
    'es-ES': '/static/images/flags/es-ES.png',
    'ru-RU': '/static/images/flags/ru-RU.png',
    'it-IT': '/static/images/flags/it-IT.png',
    'bg-BG': '/static/images/flags/bg-BG.png',
  };
}
